<script setup>
  import Breadcrumb from '@/components/Breadcrumb'
  import Pagination from '@/components/Pagination'
  import commodityItem from '@/views/index/compontent/commodity-item'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    queryParms: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })

  const data = reactive({
    loading: false,
    discountsObj: [],
    list: [],
    commodityItemLength: 5
  })

  const paginationData = reactive({
    page: 1,
    pageSize: 20,
    total: 0
  })

  // 获取折扣活动列表
  const getDiscountsList = (id) => {
    data.loading = true
    api.product.getDiscountsList(id).then((res) => {
      data.discountsObj = res.data.discountInfoSimpleDto
      data.list = res.data.productDiscountDtoList || []
      data.loading = false
      console.log(res, res.data.productDiscountDtoList, '获取折扣活动列表')
    })
  }

  // 分页
  const handCurrenPage = (val) => {
    paginationData.page = val
    getList()
    JLTools.scrollTo(300)
  }

  // 查询商品
  const getList = () => {
    data.loading = true
    let query = {
      'executed.equals': 'true',
      size: paginationData.pageSize,
      page: paginationData.page - 1,
      'productType.equals': 'GENERAL'
    }
    if (!JLTools.isNull(props.queryParms.firstCategoryId)) {
      query['firstCategoryId.equals'] = props.queryParms.firstCategoryId
    }
    if (!JLTools.isNull(props.queryParms.secondCategoryId)) {
      query['secondCategoryId.equals'] = props.queryParms.secondCategoryId
    }
    if (!JLTools.isNull(props.queryParms.thirdCategoryId)) {
      query['thirdCategoryId.equals'] = props.queryParms.thirdCategoryId
    }
    // if (!JLTools.isNull(sortItem)) {
    //   query.sort = sortItem
    // }
    // if (!JLTools.isNull(greaterThan)) {
    //   query['amount.greaterThan'] = greaterThan
    // }
    // if (!JLTools.isNull(lessThan)) {
    //   query['amount.lessThan'] = lessThan
    // }
    api.product.getProducts(query).then((res) => {
      data.list = res.data
      paginationData.total = res.headers['x-total-count']
      data.loading = false
      console.log('商品列表结果', res)
    })
  }

  watch(
    () => HsystemStore.breadcrumbList,
    (newVal, oldVal) => {
      //   init()
    }
  )

  defineExpose({
    getList,
    handCurrenPage
  })
</script>

<template>
  <div class="list-box mb20" v-loading="data.loading">
    <Breadcrumb />
    <div class="card-box-container" :style="{ gridTemplateColumns: `repeat(${data.commodityItemLength}, 1fr)` }">
      <div v-for="item of data.list" :key="item.id" class="card-box">
        <commodity-item :item="item" />
      </div>
    </div>

    <div class="rowCC mt20 w12">
      <Pagination
        :config="{ total: paginationData.total, pageSize: paginationData.pageSize, currentPage: paginationData.page }"
        v-if="paginationData.total > 10"
        @currentPage="handCurrenPage"
      ></Pagination>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .list-box {
    width: 100%;
    min-height: 400px;
    .card-box-container {
      width: 100%;
      display: grid;
      gap: 32px 16px;
      -webkit-box-pack: justify;
      justify-content: space-between;
    }
  }
</style>
